import Index from "./index.vue";
import { defineAsyncComponent } from "vue";
import { CustomerApplicationsViewPage } from "./index";
import { createRouter, createWebHistory } from "vue-router";
import BasePage from "./pages/BasePage.vue";

const routes = [
  { path: "/", name: "landing", component: Index },
  {
    path: "/pending",
    name: "pending",
    component: CustomerApplicationsViewPage,
  },
  {
    path: "/product-approval",
    name: "product-approval",
    component: BasePage,
    redirect: "/product-approval/home",
    children: [
      {
        path: "home",
        name: "product-approval-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/ProductApproval.vue"),
          }),
      },
      {
        path: "provide-information/:action/:caseReference",
        name: "provide-information",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/ProvideInformation.vue"),
          }),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
