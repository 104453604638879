import { CustomerData } from "../api.generated/models/CustomerData";

export function formatYearlyVolume(volume: number | undefined | null): string {
  if (!volume) return "$0 /yr";
  return `$${volume.toLocaleString()} /yr`;
}

export function formatDailyImpressions(
  impressions: number | undefined | null
): string {
  if (!impressions) return "0 per day";
  return `${impressions.toLocaleString()} per day`;
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return date.toLocaleDateString(userLocale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formatUseCase(
  useCase: CustomerData.use_case | undefined | null
): string {
  switch (useCase) {
    case CustomerData.use_case.EMPLOYEE_BENEFIT:
      return "Employee Benefit";
    case CustomerData.use_case.EMPLOYEE_REWARDS_RECOGNITION:
      return "Employee Rewards and Recognition";
    case CustomerData.use_case.GIFT_CARD_MALL:
      return "Gift Card Mall";
    case CustomerData.use_case.OTHER:
      return "Other";
    case CustomerData.use_case.RESELLER:
      return "Reseller";
    case CustomerData.use_case.SELF_SERVE:
      return "Self Serve";
    case CustomerData.use_case.MARKETING_RESEARCH_PROMOTION:
      return "Marketing Research and Promotion";
    case CustomerData.use_case.WASHOUT_LOYALTY_MEMBER_BENEFITS:
      return "Cashout and Loyalty (or member benefits)";
    case CustomerData.use_case.CASHOUT_LOYALTY_MEMBER_BENEFITS:
      return "Cashout and Loyalty (or member benefits)";
    default:
      return "";
  }
}

export function formatSnakeCaseToRegular(str: string) {
  console.log(str);
  return (str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()).replace(
    "_",
    " "
  );
}

export function formatBoolToString(input: boolean): string {
  return input ? "Yes" : "No";
}

export function formatAccessValue(access: CustomerData.access): string {
  switch (access) {
    case CustomerData.access.PUBLIC_PROGRAM:
      return "Public Program";
    case CustomerData.access.OPEN_PROGRAM:
      return "Open Program";
    case CustomerData.access.CLOSED_PROGRAM:
      return "Closed Program";
  }
}

export function formatMarketingValue(
  marketing: CustomerData.marketing
): string {
  switch (marketing) {
    case CustomerData.marketing.NO:
      return "No";
    default:
      return "Yes";
  }
}
