import { DecisionStatusEnum } from "../api.generated";

export function formatDecisionStatus(status: string) {
  switch (status.toUpperCase()) {
    case DecisionStatusEnum.DENIED:
      return "Declined";
    case DecisionStatusEnum.INFORMATION_REQUESTED:
      return "Information required";
    case DecisionStatusEnum.APPROVED:
      return "Approved";
    case DecisionStatusEnum.PENDING:
      return "Pending Approval";
    default:
      return status.charAt(0).toUpperCase() + status.substring(1).toLowerCase();
  }
}

export function decisionStylePicker(decision: string) {
  switch (decision) {
    case DecisionStatusEnum.DENIED:
      return "bg-red-100 text-black";
    case DecisionStatusEnum.INFORMATION_REQUESTED:
      return "bg-yellow-100 text-black";
    case DecisionStatusEnum.APPROVED:
      return "bg-lime-100 text-black";
    case DecisionStatusEnum.PENDING:
      return "bg-gray-300 text-black";
  }
}
