<script setup lang="ts">
import { onMounted, ref } from "vue";
import { sendReadyEvent } from "./parent-interface";
import broadcastElementSize from "./composables/broadcastElementSize";
import { OpenAPI } from "./api.generated";
import { OpenAPI as BrandApprovalApplicationServiceAPI } from "./api/brand-approval-application-service";

OpenAPI.BASE = import.meta.env.VITE_BRAND_APPROVAL_SERVICE_BASE_URL as string;
BrandApprovalApplicationServiceAPI.BASE =
  import.meta.env.VITE_BRAND_APPROVAL_APPLICATION_SERVICE_BASE_URL;

onMounted(sendReadyEvent);
const mainElementRef = ref<HTMLElement>();
broadcastElementSize(mainElementRef);
</script>
<template>
  <div name="resize-element" ref="mainElementRef">
    <router-view />
  </div>
</template>
