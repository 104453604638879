import { DecisionType } from "../models";
import { Decision, DecisionStatusEnum } from "../api.generated";
import decision_type = Decision.decision_type;

function AddDecisionToNewStatusGroup(
  newDecisionStatus: DecisionStatusEnum,
  statusToDecisions: Record<string, DecisionType[]>,
  decision: DecisionType
) {
  if (!!statusToDecisions[newDecisionStatus]) {
    statusToDecisions[newDecisionStatus].push(decision);
  } else {
    statusToDecisions[newDecisionStatus] = [decision];
  }
}

function updateStatusToDecisionsForPending({
  statusToDecisions,
  customerId,
  productCodes,
  newDecisionStatus,
  newCaseReference,
  reason,
}: {
  statusToDecisions: Record<string, DecisionType[]>;
  customerId: string;
  productCodes: string[];
  newDecisionStatus: DecisionStatusEnum;
  newCaseReference?: string;
  reason?: string;
}) {
  let newDecisionList: DecisionType[] = [];
  const previousDecisionStatus = DecisionStatusEnum.PENDING;
  let currentPendingDecisions = statusToDecisions[previousDecisionStatus];
  for (let currentDecision of currentPendingDecisions) {
    let shouldUpdateDecisionStatus =
      productCodes.indexOf(currentDecision.productCode) != -1;
    if (shouldUpdateDecisionStatus) {
      let updatedDecision = {
        customerId: customerId,
        customerData: currentDecision.customerData,
        productCode: currentDecision.productCode,
        decision: newDecisionStatus,
        caseReference: newCaseReference || currentDecision.caseReference,
        decisionTime: Date.now().toString(),
        decisionType: decision_type.MANUAL,
        overrideReason: reason ? reason : null,
      };
      AddDecisionToNewStatusGroup(
        newDecisionStatus,
        statusToDecisions,
        updatedDecision
      );
    } else {
      newDecisionList.push(currentDecision);
    }
  }
  statusToDecisions[DecisionStatusEnum.PENDING] = newDecisionList;
}

export default updateStatusToDecisionsForPending;
