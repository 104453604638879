import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { addSentry } from "./tracing/sentry";
import { createPinia } from "pinia";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

const pinia = createPinia();

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
addSentry(app, router);

app.use(pinia);
app.use(router);
app.mount("#app");
