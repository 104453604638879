<script setup lang="ts">
import { FileUploadItem, TwoFileUpload, TwoText } from "@wegift/two-components";
import { assert } from "typed-assert";
import {
  BrandApprovalApplicationService,
  BrandApprovalFileUploadResponse,
} from "../../api/brand-approval-application-service";
import Modal from "./Modal.vue";
import { ref, computed } from "vue";

const preSignedUrlsResponses: Record<string, BrandApprovalFileUploadResponse> =
  {};
const files = ref<string[]>([]);
const attachments = ref<string[]>(files.value);

const infoRequested = ref("");
const isInfoRequestedEmpty = computed(() => {
  return !infoRequested.value.trim().length;
});

const emit = defineEmits(["requestMoreInfoButtonClicked"]);

const extractActualFileNameFromFileKey = (fileKey: string) => {
  // backend prefixes {uuid}_ to file name for creating file key so we have to split, remove first elem and rejoin to get back file name
  return fileKey.split("_").slice(1).join("");
};

const getUploadedFilesKeys = (
  files: FileUploadItem[],
  existingFileFieldKeys: string[] = []
): string[] => {
  return (
    files
      .filter((file) => file.uploaded)
      // if preselected file, return find existing file key and return it, else return signed url response field key set by backend
      .map((file) => {
        if (file.preSelected) {
          return existingFileFieldKeys.find(
            (key) => file.name === extractActualFileNameFromFileKey(key)
          );
        } else return preSignedUrlsResponses[file.name].fields.key;
      })
  );
};

const uploadFileFn = async (file: FileUploadItem) => {
  const preSignedUrlUploadResponse =
    await BrandApprovalApplicationService.uploadFilesWithBrandApprovalApplication(
      { fileName: file.name }
    );
  const selectedFileRaw = file.raw;
  assert(selectedFileRaw !== undefined);
  const formData = new FormData();
  // store response for constructing get urls on successful upload
  preSignedUrlsResponses[file.name] = preSignedUrlUploadResponse;
  // copy form fields returned by signed url to FormData
  Object.entries(preSignedUrlUploadResponse.fields).forEach((entry) => {
    if (typeof entry[1] === "string") formData.append(entry[0], entry[1]);
  });
  formData.append("file", selectedFileRaw!);
  return fetch(preSignedUrlUploadResponse.url, {
    method: "POST",
    body: formData,
  });
};

const handleSubmit = () => {
  console.log(Object.values(attachments.value));
  emit("requestMoreInfoButtonClicked", {
    infoRequested: infoRequested.value,
    attachments: Object.values(attachments.value),
  });
};
</script>

<template>
  <Modal
    @actioned="handleSubmit"
    :isActionButtonDisabled="isInfoRequestedEmpty"
  >
    <template #header>Request More Information</template>
    <template #body>
      <p class="mb-4">
        Please, specify the questions you would like to ask this customer.
      </p>

      <p class="mb-4">
        Your questions will be sent to them via email and we will notify you
        once they have provided more information.
      </p>

      <p class="text-gray-900 font-semibold mb-4">
        Note: Once you've requested more information from the customer, you will
        need to wait for a response before being able to Approve or Decline
        their application for the selected product/s.
      </p>
      <textarea
        v-model="infoRequested"
        class="block p-2.5 w-full text-sm text-gray-800 bg-gray-100 rounded-lg focus:ring-blue-500 focus:border-blue-300"
        rows="4"
      />
      <p class="mt-2.5">
        <two-text type="text-md font-semiblack font-heading">
          Upload files
        </two-text>
        <two-text type="text-md font-heading font-light">(optional)</two-text>
      </p>
      <two-file-upload
        :upload-file-fn="uploadFileFn"
        :multiple="true"
        @change="attachments = getUploadedFilesKeys($event, attachments)"
      >
        Browse files
      </two-file-upload>
    </template>
    <template #action-button>Request Information</template>
  </Modal>
</template>
