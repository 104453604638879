<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { getDecisions } from "../services/decisions";
import DecisionsView from "./DecisionsView.vue";
import Banner from "./Banner.vue";
import { DecisionStatusEnum } from "../api.generated/models/DecisionStatusEnum";
import { LoadingSpinner } from "@wegift/two-components";
import { DecisionType } from "../models";
import { ProductCodeToNameMap, BannerType } from "../utils/types";
import { captureException } from "../tracing/sentry";

const { productCodes, productCodesAndNames } = defineProps<{
  productCodes: Array<string>;
  productCodesAndNames: ProductCodeToNameMap;
}>();

const title = "Customer Applications";
let selectedDecisions = ref<Array<DecisionType>>([]);
let errorMessage = ref();
let isLoading = ref();
let loadedDecisionType = ref<DecisionStatusEnum>(DecisionStatusEnum.PENDING);

const fetchDecisions = async (decisionStatus: DecisionStatusEnum) => {
  try {
    isLoading.value = true;
    selectedDecisions.value = await getDecisions(decisionStatus, productCodes);
  } catch (e) {
    captureException("An error occurred while fetching the decisions: ", e);
    errorMessage.value = "An error occurred! 😧";
  } finally {
    isLoading.value = false;
  }
};
const handleCustomerRowDataClosed = async () => {
  await fetchDecisions(loadedDecisionType.value);
};

async function handleNewDecisionType(newDecisionType: DecisionStatusEnum) {
  loadedDecisionType.value = newDecisionType;
  await fetchDecisions(loadedDecisionType.value);
}

onMounted(async () => {
  await fetchDecisions(DecisionStatusEnum.PENDING);
});
</script>

<template>
  <div>
    <div v-if="isLoading" class="spinner-container">
      <LoadingSpinner />
    </div>
    <div v-else-if="!!selectedDecisions && !errorMessage">
      <DecisionsView
        :title="title"
        :decisions="selectedDecisions"
        :productCodesAndNames="productCodesAndNames"
        :loadedDecisionType="loadedDecisionType"
        @customerRowDataClosed="handleCustomerRowDataClosed"
        @newDecisionType="handleNewDecisionType"
      />
    </div>
    <div v-else-if="errorMessage">
      <Banner :message="errorMessage" :type="BannerType.Alert" />
    </div>
    <div v-else>
      <Banner
        message="Something went wrong. Please, contact Runa support."
        :type="BannerType.Alert"
      />
    </div>
  </div>
</template>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
}
</style>
