import { defineStore } from "pinia";
import { ApprovalApplication } from "../models";

export const useCustomerApplicationStore = defineStore("customerApplications", {
  state: () => ({
    productCodes: null as string[] | null,
    selectedCustomer: null as ApprovalApplication | null,
  }),
  actions: {
    setCustomerApplicationStore(productCodes: string[]) {
      this.productCodes = productCodes;
    },
    setSelectedCustomer(customer: ApprovalApplication | null) {
      this.selectedCustomer = customer;
    },
  },
});
