import { CustomerData } from "../api.generated";
import AccessType = CustomerData.access;

export function getAccessAdditionalInfo(access: CustomerData.access) {
  switch (access) {
    case AccessType.PUBLIC_PROGRAM:
      return "No signup is required to purchase a gift card, and there are no restrictions.";
    case AccessType.OPEN_PROGRAM:
      return "Anyone can publicly signup as a requirement to purchase a gift card within the program.";
    case AccessType.CLOSED_PROGRAM:
      return "Signup and access to the program is exclusive to members only.";
  }
}

export function getMarketingAdditionalInfo(
  marketing: CustomerData.marketing
): string {
  switch (marketing) {
    case CustomerData.marketing.NO:
      return "No active marketing outside of catalogue listing";
    default:
      return "Active marketing of brand assets (e.g. Emails, Notifications, Printed Collateral)";
  }
}
