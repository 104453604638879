import { ApiError, DefaultService } from "../api.generated";

export async function getAttachments(customer_id: string): Promise<string[]> {
  try {
    let response = await DefaultService.getApiV1Attachment(customer_id);
    if (response && response.attachments) {
      return response.attachments;
    }

    return [];
  } catch (e) {
    let result = <ApiError>e;
    console.error(`Error when calling attachment api: ${result.message}`, e);
    throw e;
  }
}
