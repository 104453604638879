import _ from "lodash";
import { ApprovalApplication, DecisionType } from "../models";

export function aggregateDecisionsIntoApplications(
  decisions: DecisionType[]
): Array<ApprovalApplication> {
  const pendingApplicationsMap: { [key in string]: ApprovalApplication } = {};
  const sortedDecisions = decisions.sort((a, b) => {
    const dateA = new Date(a.decisionTime).getTime();
    const dateB = new Date(b.decisionTime).getTime();
    return dateA - dateB;
  });
  decisions.forEach((decision) => {
    const customerId = decision.customerId as string;

    if (!pendingApplicationsMap[customerId]) {
      pendingApplicationsMap[customerId] = {
        customerId: customerId,
        customerData:
          _.findLast(sortedDecisions, (d) => d.customerId === customerId)
            ?.customerData || {},
        decisions: [],
      };
    }

    pendingApplicationsMap[customerId].decisions?.push(decision);
  });
  return Object.values(pendingApplicationsMap);
}

export function groupByStatus(decisionList: DecisionType[]) {
  let decisionsByStatusMap: Record<string, DecisionType[]> = {};
  for (let decision of decisionList) {
    const decisionStatus = decision.decision;
    let doesDecisionStatusExist = decisionStatus in decisionsByStatusMap;
    if (!doesDecisionStatusExist) {
      decisionsByStatusMap[decisionStatus] = [decision];
    } else {
      decisionsByStatusMap[decisionStatus].push(decision);
    }
  }
  return decisionsByStatusMap;
}
