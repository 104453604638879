<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { TwoButton, TwoCheckbox } from "@wegift/two-components";
import { useGlobalStore } from "../stores/global";
import { useProductStore } from "../stores/product";
import { ApprovalApplication, DecisionType } from "../models";
import Banner from "./Banner.vue";
import {
  APPROVAL_REASON,
  approvePendingRequests,
  declinePendingRequests,
  requestMoreInfoForPendingProducts,
} from "../services/makeDecision";
import { BannerType, ProductCodeToNameMap } from "../utils/types";
import DeclineModal from "./modals/DeclineModal.vue";
import ApproveModal from "./modals/ApproveModal.vue";
import RequestMoreInfoModal from "./modals/RequestMoreInfoModal.vue";
import { DecisionStatusEnum } from "../api.generated";
import updateStatusToDecisionsForPending from "../utils/updateStatusToDecisionsForPending";
import { getAttachments } from "../services/getAttachments";
import CustomerDataRows from "./CustomerDataRows.vue";
import ProductApplications from "./ProductApplications.vue";
import { groupByStatus } from "../utils/decisionsAggregator";
import { getDecisionsForCustomerAndProducts } from "../services/decisions";
import {
  BAND_APPROVE_ACTION_VIEW,
  BAND_APPROVE_ACTION_EDIT,
} from "../constants";
import { captureException } from "../tracing/sentry";

defineEmits(["close"]);

const router = useRouter();
const globalStore = useGlobalStore();
const productStore = useProductStore();

const { application, productCodesAndNames } = defineProps<{
  application: ApprovalApplication;
  productCodesAndNames: ProductCodeToNameMap;
}>();

let selectedProductCodes = ref<string[]>([]);
let allSelectedBox = ref<boolean>(true);
let productCodesAvailable: string[] = [];

let attachmentLinks = ref<string[]>([]);

let isDeclineModalVisible = ref(false);
let isApproveModalVisible = ref(false);
let isRequestMoreInfoModalVisible = ref(false);

let decisionsStatusToDecisions = ref<Record<string, DecisionType[]>>();

let errorMessage = ref("");
let infoRequestedBannerMessage = "";

const fetchAttachments = async () => {
  try {
    attachmentLinks.value = await getAttachments(application.customerId);
  } catch (e) {
    captureException(
      "An error occurred while loading the attachments: " +
        application.customerId,
      e
    );
    errorMessage.value =
      "An error occurred while loading the attachments. Please try again later.";
  }
};

onMounted(async () => {
  await fetchAttachments();
});

if (application) {
  productCodesAvailable = Object.keys(productCodesAndNames);
  try {
    let allDecisions = await getDecisionsForCustomerAndProducts(
      application.customerId,
      productCodesAvailable
    );
    decisionsStatusToDecisions.value = groupByStatus(allDecisions);
    selectAllPendingDecisions();
  } catch (e) {
    captureException("Error when calling decision api: ", e);
    errorMessage.value = "Cannot fetch application.";
  }
  infoRequestedBannerMessage = `You have requested more information from ${application.customerData.tradingName},
 once they supply this information their application will be updated.`;
}

function selectAllPendingDecisions() {
  const pendingDecisions = getPendingDecisionsRef(decisionsStatusToDecisions);
  selectedProductCodes.value = [];
  if (pendingDecisions) {
    selectedProductCodes.value = pendingDecisions.map((p) => p.productCode);
  }
}

function toggleSelectAllPendingProducts() {
  if (allSelectedBox.value) {
    selectedProductCodes.value = [];
  } else {
    selectAllPendingDecisions();
  }
  allSelectedBox.value = !allSelectedBox.value;
}

function getSelectedPendingProductCodes() {
  let pendingDecisions = getPendingDecisionsRef(decisionsStatusToDecisions);
  const selectedProductPendingDecisions = pendingDecisions.filter((decision) =>
    selectedProductCodes.value.includes(decision.productCode)
  );
  const selectedPendingProductCodes = selectedProductPendingDecisions.map(
    (decision) => decision.productCode
  );
  return selectedPendingProductCodes;
}

function doAnySelectedHaveACaseReference() {
  let productCodesToPendingDecisions = getProductCodeToPendingDecisions();
  let productCodesWithCaseRefs = selectedProductCodes.value.filter((s) =>
    hasCaseReference(productCodesToPendingDecisions, s)
  );
  return productCodesWithCaseRefs.length > 0;
}

async function requestMoreInfo({
  infoRequested,
  attachments,
}: {
  infoRequested: string;
  attachments: string[];
}) {
  closeRequestMoreInfoModal();

  try {
    let response = await requestMoreInfoForPendingProducts(
      getSelectedPendingProductCodes(),
      application.customerId,
      infoRequested,
      attachments
    );
    if (decisionsStatusToDecisions.value) {
      updateStatusToDecisionsForPending({
        statusToDecisions: decisionsStatusToDecisions.value,
        customerId: application.customerId,
        productCodes: getSelectedPendingProductCodes(),
        newDecisionStatus: DecisionStatusEnum.INFORMATION_REQUESTED,
        newCaseReference: response.caseReference,
        reason: infoRequested,
      });
    }
    errorMessage.value = "";

    selectedProductCodes.value = [];
    allSelectedBox.value = false;
  } catch (e) {
    captureException(
      "An error occurred when trying to ask for more information:" +
        application.customerId,
      e
    );
    errorMessage.value =
      "An error occurred when trying to ask for more information";
  }
}

async function approvePending() {
  closeApproveModal();
  try {
    if (doAnySelectedHaveACaseReference()) {
      let product_codes = getSelectedPendingProductCodes();
      for (let product_code of product_codes) {
        await approvePendingRequests([product_code], application.customerId);

        if (decisionsStatusToDecisions.value) {
          updateStatusToDecisionsForPending({
            statusToDecisions: decisionsStatusToDecisions.value,
            customerId: application.customerId,
            productCodes: [product_code],
            newDecisionStatus: DecisionStatusEnum.APPROVED,
            reason: APPROVAL_REASON,
          });
        }
      }
    } else {
      await approvePendingRequests(
        getSelectedPendingProductCodes(),
        application.customerId
      );

      if (decisionsStatusToDecisions.value) {
        updateStatusToDecisionsForPending({
          statusToDecisions: decisionsStatusToDecisions.value,
          customerId: application.customerId,
          productCodes: getSelectedPendingProductCodes(),
          newDecisionStatus: DecisionStatusEnum.APPROVED,
          reason: APPROVAL_REASON,
        });
      }
    }
    errorMessage.value = "";

    selectedProductCodes.value = [];
    allSelectedBox.value = false;
  } catch (e) {
    captureException(
      "An error occurred when trying to approve: " + application.customerId,
      e
    );
    errorMessage.value = "An error occurred when trying to approve. 😧";
  }
}

async function declinePending(declineReason: string) {
  closeDeclineModal();

  try {
    if (doAnySelectedHaveACaseReference()) {
      let product_codes = getSelectedPendingProductCodes();
      for (let product_code of product_codes) {
        await declinePendingRequests(
          [product_code],
          application.customerId,
          declineReason
        );

        if (decisionsStatusToDecisions.value) {
          updateStatusToDecisionsForPending({
            statusToDecisions: decisionsStatusToDecisions.value,
            customerId: application.customerId,
            productCodes: [product_code],
            newDecisionStatus: DecisionStatusEnum.DENIED,
            reason: declineReason,
          });
        }
      }
    } else {
      await declinePendingRequests(
        getSelectedPendingProductCodes(),
        application.customerId,
        declineReason
      );

      if (decisionsStatusToDecisions.value) {
        updateStatusToDecisionsForPending({
          statusToDecisions: decisionsStatusToDecisions.value,
          customerId: application.customerId,
          productCodes: getSelectedPendingProductCodes(),
          newDecisionStatus: DecisionStatusEnum.DENIED,
          reason: declineReason,
        });
      }
    }
    errorMessage.value = "";

    selectedProductCodes.value = [];
    allSelectedBox.value = false;
  } catch (e) {
    captureException(
      "An error occurred when trying to decline: " + application.customerId,
      e
    );
    errorMessage.value = "An error occurred when trying to decline! 😧";
  }
}

function showRequestMoreInfoModal() {
  isRequestMoreInfoModalVisible.value = true;
}

function showDeclineModal() {
  isDeclineModalVisible.value = true;
}

function showApproveModal() {
  isApproveModalVisible.value = true;
}

function closeDeclineModal() {
  isDeclineModalVisible.value = false;
}

function closeApproveModal() {
  isApproveModalVisible.value = false;
}

function closeRequestMoreInfoModal() {
  isRequestMoreInfoModalVisible.value = false;
}

function shouldDisplayProductsFor(decisions: DecisionType[]) {
  return !!decisions && decisions.length !== 0;
}

function getPendingDecisionsRef(
  decisionsStatusToDecisions: Ref<Record<string, DecisionType[]> | undefined>
) {
  if (decisionsStatusToDecisions.value) {
    return decisionsStatusToDecisions.value[DecisionStatusEnum.PENDING];
  }
  return [];
}

function getPendingDecisions(
  decisionsStatusToDecisions: Record<string, DecisionType[]> | undefined
) {
  if (decisionsStatusToDecisions) {
    return decisionsStatusToDecisions[DecisionStatusEnum.PENDING];
  }
  return [];
}

function getDecisionsWithStatus(
  status: DecisionStatusEnum,
  decisionsStatusToDecisions: Record<string, DecisionType[]> | undefined
) {
  if (decisionsStatusToDecisions) {
    return decisionsStatusToDecisions[status];
  }
  return [];
}

function productToggled(productCode: string) {
  const indexOfProductCode = selectedProductCodes.value.indexOf(productCode);
  let isProductAlreadySelected = indexOfProductCode != -1;
  if (isProductAlreadySelected) {
    selectedProductCodes.value.splice(indexOfProductCode, 1);
  } else {
    selectedProductCodes.value.push(productCode);
  }
  allSelectedBox.value = isAllPendingProductsSelected();
}

function isAllPendingProductsSelected() {
  return (
    selectedProductCodes.value.length ===
    getPendingDecisionsRef(decisionsStatusToDecisions).length
  );
}

function handleViewCaseHistory(caseReference: string, isPending: boolean) {
  globalStore.setGlobalData(application.customerId);
  productStore.productCodesAndNames = productCodesAndNames;
  router.push({
    name: "provide-information",
    params: {
      action: isPending ? BAND_APPROVE_ACTION_EDIT : BAND_APPROVE_ACTION_VIEW,
      caseReference: caseReference,
    },
  });
}

function shouldDisableRequestMoreInfoButton() {
  let areProductCodesSelected = selectedProductCodes.value.length !== 0;
  return !areProductCodesSelected || doAnySelectedHaveACaseReference();
}

function getProductCodeToPendingDecisions() {
  let pendingDecisions = getPendingDecisions(decisionsStatusToDecisions.value);
  pendingDecisions.map((p) => p.productCode);
  let productCodesToPendingDecisions: Record<string, DecisionType> = {};
  for (let decision of pendingDecisions) {
    productCodesToPendingDecisions[decision.productCode] = decision;
  }
  return productCodesToPendingDecisions;
}

function hasCaseReference(
  productCodesToPendingDecisions: any,
  productCode: string
) {
  let decision = productCodesToPendingDecisions[productCode];
  return decision.caseReference != null;
}
</script>

<template>
  <div v-if="!application" className="bg-gray-50 p-6">
    <Banner
      message="Unable to display customer details"
      :type="BannerType.Alert"
    />
  </div>
  <div v-else-if="!!errorMessage" className="bg-gray-50 p-6">
    <Banner :message="errorMessage" :type="BannerType.Alert" />
  </div>
  <DeclineModal
    v-if="isDeclineModalVisible"
    @declineButtonClicked="declinePending"
    @closed="closeDeclineModal"
    :isForMultipleProducts="selectedProductCodes.length > 1"
  />
  <ApproveModal
    v-if="isApproveModalVisible"
    @approveButtonClicked="approvePending"
    @closed="closeApproveModal"
    :isForMultipleProducts="selectedProductCodes.length > 1"
  />
  <RequestMoreInfoModal
    v-if="isRequestMoreInfoModalVisible"
    @closed="closeRequestMoreInfoModal"
    @requestMoreInfoButtonClicked="requestMoreInfo"
  />

  <div className="bg-gray-50 p-6">
    <TwoButton
      @click="$emit('close')"
      className="bg-transparent text-sm mb-4"
      :style="{ color: '#505BF0' }"
      borderLess
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 inline"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
        />
      </svg>
      Previous page
    </TwoButton>

    <div v-if="application" class="flex mb-8">
      <h1 class="text-2xl flex-1">
        {{ application.customerData.tradingName }}
      </h1>
    </div>

    <div v-if="application" class="mb-12">
      <div class="title-margin text-lg font-semibold">Pending decisions</div>
      <div
        v-if="
          shouldDisplayProductsFor(
            getPendingDecisions(decisionsStatusToDecisions)
          )
        "
      >
        <div class="flex mb-4">
          <h1 class="flex-1 flex items-center text-center text-sm ml-6">
            <TwoCheckbox
              value=""
              v-model="allSelectedBox"
              @click="toggleSelectAllPendingProducts"
            />
            <p v-if="allSelectedBox">Deselect All</p>
            <p v-else>Select All</p>
          </h1>
          <div class="flex-initial actions">
            <TwoButton
              @click="showRequestMoreInfoModal"
              :disabled="shouldDisableRequestMoreInfoButton()"
              type="button"
              shadowed
            >
              Request information
            </TwoButton>

            <TwoButton
              @click="showDeclineModal"
              :disabled="selectedProductCodes.length == 0"
              type="button"
              shadowed
            >
              Decline
            </TwoButton>
            <TwoButton
              v-on:click="showApproveModal"
              class="btn-global-approve"
              type="button"
              :disabled="selectedProductCodes.length == 0"
              shadowed
              >Approve
            </TwoButton>
          </div>
        </div>
      </div>
      <div v-else class="mt-5 text-gray-400 font-extralight">
        There are no pending decisions
      </div>

      <ProductApplications
        v-if="
          shouldDisplayProductsFor(
            getDecisionsWithStatus(
              DecisionStatusEnum.PENDING,
              decisionsStatusToDecisions
            )
          )
        "
        :shouldDisplayTitle="false"
        :bannerMessage="null"
        :productCodesAndNames="productCodesAndNames"
        :decisionList="getPendingDecisions(decisionsStatusToDecisions)"
        :selectedProductCodes="selectedProductCodes"
        @handle-view-case-history="
          ($event) => handleViewCaseHistory($event, true)
        "
        :showCaseReference="true"
        @productToggled="productToggled"
      />

      <ProductApplications
        v-if="
          shouldDisplayProductsFor(
            getDecisionsWithStatus(
              DecisionStatusEnum.INFORMATION_REQUESTED,
              decisionsStatusToDecisions
            )
          )
        "
        :shouldDisplayTitle="true"
        :bannerMessage="infoRequestedBannerMessage"
        :productCodesAndNames="productCodesAndNames"
        :decisionList="
          getDecisionsWithStatus(
            DecisionStatusEnum.INFORMATION_REQUESTED,
            decisionsStatusToDecisions
          )
        "
        :selectedProductCodes="selectedProductCodes"
        @productToggled="productToggled"
        @handle-view-case-history="handleViewCaseHistory"
      />

      <ProductApplications
        v-if="
          shouldDisplayProductsFor(
            getDecisionsWithStatus(
              DecisionStatusEnum.APPROVED,
              decisionsStatusToDecisions
            )
          )
        "
        :shouldDisplayTitle="true"
        :bannerMessage="null"
        :productCodesAndNames="productCodesAndNames"
        :decisionList="
          getDecisionsWithStatus(
            DecisionStatusEnum.APPROVED,
            decisionsStatusToDecisions
          )
        "
        :selectedProductCodes="selectedProductCodes"
        @productToggled="productToggled"
      />

      <ProductApplications
        v-if="
          shouldDisplayProductsFor(
            getDecisionsWithStatus(
              DecisionStatusEnum.DENIED,
              decisionsStatusToDecisions
            )
          )
        "
        :shouldDisplayTitle="true"
        :bannerMessage="null"
        :productCodesAndNames="productCodesAndNames"
        :decisionList="
          getDecisionsWithStatus(
            DecisionStatusEnum.DENIED,
            decisionsStatusToDecisions
          )
        "
        :selectedProductCodes="selectedProductCodes"
        @productToggled="productToggled"
      />
    </div>

    <div class="mb-12" v-if="application">
      <h2 class="text-xl mb-4">Customer Details</h2>
      <CustomerDataRows
        :customerId="application.customerId"
        :data="application.customerData"
        :attachments="attachmentLinks"
      />
    </div>
  </div>
</template>

<style scoped>
.btn-global-approve {
  background-color: #4caf50;
  color: white;
}
.btn-global-approve[disabled] {
  background-color: #4caf50;
  color: white;
}
.btn-global-approve:hover {
  background-color: #357a38;
}
.spinner-container {
  display: flex;
  justify-content: center;
}
</style>
