<script lang="ts" setup>
import { ref } from "vue";
import { useCustomerApplicationStore } from "../stores/customerApplications";
import CustomerApplicationsView from "../components/CustomerApplicationsView.vue";
import { onSetupEvent } from "../parent-interface";
import { useProductStore } from "../stores/product";
import { OpenAPI as BrandApprovalApplicationServiceAPI } from "../api/brand-approval-application-service";
import { OpenAPI as BrandApprovalServiceAPI } from "../api.generated";

const productStore = useProductStore();

const customerApplicationStore = useCustomerApplicationStore();
let productCodes = ref(customerApplicationStore.productCodes);
let productCodesAndNames = ref(productStore.productCodesAndNames);

function getProductCodesForBrandApprovalGroup(productCodesFromEvent: string) {
  let codes = productCodesFromEvent.split(",");
  return codes.map((c) => c.trim());
}

onSetupEvent(async (event) => {
  productCodes.value = getProductCodesForBrandApprovalGroup(
    event.data.productCodes! as string
  );
  productCodesAndNames.value = event.data.productCodeToName!;
  BrandApprovalApplicationServiceAPI.TOKEN = event.data.accessTokenBAAS;
  BrandApprovalServiceAPI.TOKEN = event.data.accessToken;

  customerApplicationStore.setCustomerApplicationStore(productCodes.value);
});
</script>

<template>
  <suspense v-if="productCodes && productCodesAndNames">
    <CustomerApplicationsView
      :productCodes="productCodes"
      :productCodesAndNames="productCodesAndNames"
    ></CustomerApplicationsView>
  </suspense>
</template>
