<script setup lang="ts">
import { DecisionStatusEnum } from "../api.generated";
import {
  formatDecisionStatus,
  decisionStylePicker,
} from "../utils/decisionStatus";

const { decision } = defineProps<{
  decision: DecisionStatusEnum;
}>();

const decisionStyle = decisionStylePicker(decision);
</script>
<template>
  <div
    :class="[
      'pill inline-flex justify-center text-center no-underline rounded-full font-sans text-sm mr-4 py-1.5 px-3',
      decisionStyle,
    ]"
  >
    <span>{{ formatDecisionStatus(decision) }}</span>
  </div>
</template>

<style scoped>
.pill {
  min-width: 100px;
}
</style>
