<script setup lang="ts">
import {
  getAccessAdditionalInfo,
  getMarketingAdditionalInfo,
} from "../utils/customerDataAdditionalInfo";
import { CustomerData } from "../models/decisions";
import { CustomerData as CustomerDataType } from "../api.generated/models/CustomerData";

import { computed } from "vue";
import {
  formatAccessValue,
  formatBoolToString,
  formatMarketingValue,
  formatUseCase,
  formatYearlyVolume,
  formatDailyImpressions,
  formatDate,
} from "../utils/customerDataFormater";

const props = defineProps<{
  customerId: string;
  data: CustomerData;
  attachments: Array<string>;
}>();

interface DisplayDataType {
  fieldName?: string;
  formattedFieldName: string;
  formattedFieldValue: string | boolean | number;
  additionalInfo?: string;
}

function getDisplayUseCase(
  useCase: CustomerDataType.use_case,
  otherUseCase: string | undefined | null
): string {
  return useCase == CustomerDataType.use_case.OTHER && otherUseCase
    ? otherUseCase
    : formatUseCase(useCase);
}

const displayData = computed((): Array<DisplayDataType> => {
  const data = props.data;
  const dataRows: DisplayDataType[] = [];

  if (data["tradingName"]) {
    dataRows.push({
      formattedFieldName: "Company trading name",
      formattedFieldValue: data["tradingName"],
    });
  }

  if (props.customerId) {
    dataRows.push({
      formattedFieldName: "Customer Number",
      formattedFieldValue: props.customerId,
    });
  }

  if (data["companyDescription"]) {
    dataRows.push({
      formattedFieldName: "Company description",
      formattedFieldValue: data["companyDescription"],
    });
  }

  if (data["websiteUrl"]) {
    dataRows.push({
      fieldName: "websiteUrl",
      formattedFieldName: "Website URL",
      formattedFieldValue: data["websiteUrl"],
    });
  }

  if (data["companyModel"]) {
    dataRows.push({
      formattedFieldName: "Business type",
      formattedFieldValue: data["companyModel"],
    });
  }

  if ("revealDiscount" in data && data["revealDiscount"] !== undefined) {
    dataRows.push({
      formattedFieldName: "Discount offered to consumer",
      formattedFieldValue: formatBoolToString(data["revealDiscount"]),
    });
  }

  if ("crypto" in data && data["crypto"] !== undefined) {
    dataRows.push({
      formattedFieldName: "Crypto association",
      formattedFieldValue: formatBoolToString(data["crypto"]),
    });
  }

  if (data["useCase"]) {
    dataRows.push({
      formattedFieldName: "Use case",
      formattedFieldValue: getDisplayUseCase(
        data["useCase"],
        data["otherUseCase"]
      ),
    });
  }

  if (data["access"]) {
    dataRows.push({
      formattedFieldName: "Who has access",
      formattedFieldValue: formatAccessValue(data["access"]),
      additionalInfo: getAccessAdditionalInfo(data["access"]),
    });
  }

  if ("marketing" in data && data["marketing"] !== undefined) {
    dataRows.push({
      formattedFieldName: "Marketing",
      formattedFieldValue: formatMarketingValue(data["marketing"]),
      additionalInfo: getMarketingAdditionalInfo(data["marketing"]),
    });
  }

  if ("volume" in data) {
    dataRows.push({
      formattedFieldName: "Volume forecast",
      formattedFieldValue: formatYearlyVolume(data["volume"]),
    });
  }

  if (data["expectedNumberOfImpressions"]) {
    dataRows.push({
      formattedFieldName: "Number of impressions",
      formattedFieldValue: formatDailyImpressions(
        data["expectedNumberOfImpressions"]
      ),
    });
  }

  if (data["campaignLaunchDate"]) {
    dataRows.push({
      formattedFieldName: "Launch date",
      formattedFieldValue: formatDate(data["campaignLaunchDate"]),
    });
  }

  if (data["targetAudience"]) {
    dataRows.push({
      formattedFieldName: "Target audience",
      formattedFieldValue: data["targetAudience"],
    });
  }
  if (data["giftCardUse"]) {
    dataRows.push({
      formattedFieldName: "All of the ways they plan to use gift cards",
      formattedFieldValue: formatToHtmlList(data["giftCardUse"], "list-disc"),
    });
  }

  if (data["userExperienceDescription"]) {
    dataRows.push({
      formattedFieldName: "How will a user participate in the program?",
      formattedFieldValue: formatToHtmlList(
        data["userExperienceDescription"],
        "list-decimal"
      ),
    });
  }

  if (data["userExperienceFileUrl"]) {
    dataRows.push({
      fieldName: "websiteUrl",
      formattedFieldName: "User experience visuals URL",
      formattedFieldValue: data["userExperienceFileUrl"],
    });
  }

  return dataRows;
});

const formatToHtmlList = (text: string, listType: string) => {
  // Split the string into lines
  const lines = text.split("\n");
  if (!lines[0].startsWith("•") && !lines[0].match(/^\d+\./)) {
    // If not, return original text
    return text;
  }

  // Start the HTML list
  let htmlList = `<ul class='${listType} list-inside'>\n`;

  // Process each line
  for (let i = 0; i < lines.length; i++) {
    // Ignore empty lines
    if (lines[i].trim() === "") continue;

    // Check if line starts with bullet point or number, then strip off leading characters
    let content = lines[i].startsWith("•")
      ? lines[i].slice(1).trim()
      : lines[i].split(".").slice(1).join(".").trim();

    // Add the content as a list item
    htmlList +=
      `<li><span class=${listType === "list-decimal" && "pl-1"}>` +
      content +
      `</span></li>\n`;
  }

  // End the HTML list
  htmlList += "</ul>";

  return htmlList;
};

const attachmentsData = computed((): Array<{ url: string; name: string }> => {
  if (!props.attachments || !props.attachments.length) {
    return [];
  }

  return props.attachments.map((file) => {
    const url = new URL(file);

    /**
     * Parse file names from URL. First, get the last part of the URL path section,
     * as that's the file name. Once we have the file name, we need to remove the prefix Distro adds.
     * Today, Distro is adding a UUID separated by an underscode as prefix. After removing that,
     * we have the original file name.
     */
    const fileNameFromPath = url.pathname.slice(
      url.pathname.lastIndexOf("/") + 1
    );
    const nameWithoutPrefix = fileNameFromPath.slice(
      fileNameFromPath.indexOf("_") + 1
    );

    return {
      name: decodeURI(nameWithoutPrefix),
      url: file,
    };
  });
});
</script>

<template>
  <div>
    <div
      v-for="dataRow in displayData"
      class="flex py-3 border-b border-grey-100 text-grey-550 text-sm"
    >
      <span class="flex-30">{{ dataRow.formattedFieldName }}</span>
      <span class="flex flex-70 flex-row font-semibold align-top">
        <a
          v-if="
            dataRow.fieldName &&
            (dataRow.fieldName === 'websiteUrl' ||
              dataRow.fieldName === 'userExperienceFileUrl') &&
            typeof dataRow.formattedFieldValue == 'string'
          "
          :style="{ color: '#505BF0' }"
          :href="dataRow.formattedFieldValue"
          target="_blank"
          class="inline-block hover:text-blue-700 transition duration-50 ease-in-out"
        >
          <p class="inline-block align-top mt-1">
            {{ dataRow.formattedFieldValue }}
          </p>
        </a>
        <p
          v-else="
            dataRow.fieldName &&
            (dataRow.fieldName === 'websiteUrl' ||
              dataRow.fieldName === 'userExperienceFileUrl') &&
            typeof dataRow.formattedFieldValue == 'string'
          "
          v-html="dataRow.formattedFieldValue as string"
          class="inline-block align-top mt-1"
        />
        <a
          data-bs-toggle="tooltip"
          class="inline-block ml-2 hover:text-blue-700 transition duration-50 ease-in-out"
          :title="dataRow.additionalInfo"
          v-if="dataRow.additionalInfo"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{3}"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </a>

        <a
          v-if="
            dataRow.fieldName &&
            (dataRow.fieldName === 'websiteUrl' ||
              dataRow.fieldName === 'userExperienceFileUrl') &&
            typeof dataRow.formattedFieldValue == 'string'
          "
          :style="{ color: '#505BF0' }"
          :href="dataRow.formattedFieldValue"
          target="_blank"
          class="inline-block ml-2 hover:text-blue-700 transition duration-50 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{3}"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </a>
      </span>
    </div>
    <div
      v-if="attachments.length"
      class="flex py-3 border-b border-grey-100 text-grey-550 text-sm"
    >
      <span class="flex-30">User experience visuals</span>
      <span class="flex-70 font-semibold align-top">
        <span
          v-for="(attachment, index) in attachmentsData"
          class="ml-1 first:ml-0"
        >
          <a
            :href="attachment.url"
            target="_blank"
            class="inline-block text-blue-700"
          >
            {{ attachment.name }}

            <svg
              class="inline"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.75 6.5C13.3344 6.5 13 6.16406 13 5.75C13 5.33594 13.3344 5 13.75 5H18.25C18.6656 5 19 5.33594 19 5.75V10.25C19 10.6656 18.6656 11 18.25 11C17.8344 11 17.5 10.6656 17.5 10.25V7.55938L11.2531 13.7531C10.9875 14.0719 10.5125 14.0719 10.2188 13.7531C9.92812 13.4875 9.92812 13.0125 10.2188 12.7188L16.4406 6.5H13.75ZM5 7.75C5 6.78344 5.78344 6 6.75 6H10.25C10.6656 6 11 6.33594 11 6.75C11 7.16563 10.6656 7.5 10.25 7.5H6.75C6.61188 7.5 6.5 7.6125 6.5 7.75V17.25C6.5 17.3875 6.61188 17.5 6.75 17.5H16.25C16.3875 17.5 16.5 17.3875 16.5 17.25V13.75C16.5 13.3344 16.8344 13 17.25 13C17.6656 13 18 13.3344 18 13.75V17.25C18 18.2156 17.2156 19 16.25 19H6.75C5.78344 19 5 18.2156 5 17.25V7.75Z"
                fill="#505BF0"
              />
            </svg>
          </a>
          <span v-if="index < attachments.length - 1">,</span>
        </span>
      </span>
    </div>
  </div>
</template>
