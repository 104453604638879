<script setup lang="ts">
import { ProductCodeToNameMap, BannerType } from "../utils/types";
import { DecisionType } from "../models";
import StatusPill from "./StatusPill.vue";
import { formatDecisionStatus } from "../utils/decisionStatus";
import { DecisionStatusEnum } from "../api.generated";
import Banner from "./Banner.vue";
import { TwoButton, TwoCheckbox } from "@wegift/two-components";
import { caseReferenceFormatter } from "../utils/caseReferenceFormatter";
import { getProductName } from "../stores/product";

const emit = defineEmits(["productToggled", "handleViewCaseHistory"]);

const props = defineProps<{
  shouldDisplayTitle: boolean;
  bannerMessage: string | null;
  decisionList: DecisionType[];
  selectedProductCodes: string[];
  productCodesAndNames: ProductCodeToNameMap;
  showCaseReference?: boolean;
}>();

function handleViewCaseHistory(caseReference: string) {
  emit("handleViewCaseHistory", caseReference);
}
</script>

<template>
  <div v-if="shouldDisplayTitle" class="title-margin text-lg font-semibold">
    {{ formatDecisionStatus(decisionList[0].decision) }}
  </div>

  <Banner
    v-if="!!bannerMessage"
    :type="BannerType.Info"
    :message="bannerMessage"
  ></Banner>
  <div class="bg-white shadow-xl rounded-lg p-4 divide-y">
    <div
      class="flex p-2 items-center"
      v-for="decision in decisionList"
      :key="decision.productCode"
      @click="$emit('productToggled', decision.productCode)"
    >
      <div class="pointer-events-none">
        <TwoCheckbox
          v-if="decision.decision === DecisionStatusEnum.PENDING"
          :value="decision.productCode"
          v-model="props.selectedProductCodes"
        />

        <div v-else="" id="spacer" class="w-4 h-4 mr-4" />
      </div>
      <div class="flex-none w-60 mr-4 text-sm font-semibold truncate">
        <span
          :title="
            getProductName(props.productCodesAndNames, decision.productCode)
          "
          >{{
            getProductName(props.productCodesAndNames, decision.productCode)
          }}</span
        >
      </div>
      <div class="flex flex-1 mr-4 min-w-0">
        <StatusPill
          class="shrink-0"
          :decision="decision.decision"
          :key="decision.decision"
        />
        <span class="flex flex-1 items-center text-center min-w-0">
          <span
            v-if="decision.overrideReason"
            class="text-sm truncate"
            :title="decision.overrideReason"
          >
            “{{ decision.overrideReason }}”
          </span>
          <span
            class="text-sm truncate ml-1"
            v-if="decision.caseReference"
            :title="decision.caseReference"
          >
            Case Ref: {{ caseReferenceFormatter(decision.caseReference) }}
          </span>
        </span>
      </div>
      <two-button
        v-if="showCaseReference && decision.caseReference"
        class="btn-primary"
        @click="handleViewCaseHistory(decision.caseReference as string)"
      >
        View case history
      </two-button>
    </div>
  </div>
</template>
<style scoped>
.title-margin {
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>
