<script setup lang="ts">
import { ref } from "vue";
import { DecisionStatusEnum } from "../api.generated";
import { DecisionType, ApprovalApplication } from "../models";
import { aggregateDecisionsIntoApplications } from "../utils/decisionsAggregator";
import { ProductCodeToNameMap } from "../utils/types";
import { useCustomerApplicationStore } from "../stores/customerApplications";
import ApplicationsTable from "./ApplicationsTable.vue";
import CustomerDetails from "./CustomerDetails.vue";
import DropDown from "./DropDown.vue";

const customerApplicationStore = useCustomerApplicationStore();
const emit = defineEmits(["customerRowDataClosed", "newDecisionType"]);

const { decisions, productCodesAndNames, loadedDecisionType } = defineProps<{
  title: string;
  decisions: DecisionType[];
  productCodesAndNames: ProductCodeToNameMap;
  loadedDecisionType: DecisionStatusEnum;
}>();

const applications = ref<Array<ApprovalApplication>>([]);
applications.value = aggregateDecisionsIntoApplications(decisions);

const currentRowData = ref<ApprovalApplication | null>(
  customerApplicationStore.selectedCustomer
);
const currentDecisionType = ref<DecisionStatusEnum | null>(loadedDecisionType);

const rowClicked = (clickedRowData: ApprovalApplication) => {
  currentRowData.value = clickedRowData;
  customerApplicationStore.setSelectedCustomer(clickedRowData);
};
const customerRowDataClosed = () => {
  currentRowData.value = null;
  customerApplicationStore.setSelectedCustomer(null);
  emit("customerRowDataClosed");
};

function setDecisionType(newDecision: DecisionStatusEnum) {
  currentDecisionType.value = newDecision;
  emit("newDecisionType", newDecision);
}

function shouldShowSummary(): boolean {
  return (
    currentDecisionType.value === DecisionStatusEnum.INFORMATION_REQUESTED ||
    currentDecisionType.value === DecisionStatusEnum.PENDING
  );
}
</script>

<template>
  <div v-if="!currentRowData">
    <div className="bg-gray-50 p-6">
      <div class="flex mb-4">
        <h1 class="text-2xl mb-4 mr-5">
          {{ title }} ({{ applications?.length }})
        </h1>
        <DropDown
          :selectedDecisionType="currentDecisionType"
          @selectDecisionType="setDecisionType"
        />
      </div>

      <div v-if="!applications?.length" className="mb-4">
        <h2>
          You're all set! There are no more customer applications to review at
          the moment. Come back again later.
        </h2>
      </div>

      <div v-else>
        <ApplicationsTable
          :applications="applications"
          :showApplicationSummary="shouldShowSummary()"
          @application-table-row-clicked="rowClicked"
        />
      </div>
    </div>
  </div>

  <div v-if="!!currentRowData">
    <CustomerDetails
      @close="customerRowDataClosed"
      :application="currentRowData"
      :productCodesAndNames="productCodesAndNames"
    />
  </div>
</template>

<style scoped></style>
