<script setup lang="ts">
import { ref } from "vue";
import { DecisionStatusEnum } from "../api.generated";

const emit = defineEmits(["selectDecisionType"]);

const { selectedDecisionType } = defineProps<{
  selectedDecisionType: DecisionStatusEnum | null;
}>();

const isMenuShowing = ref<boolean>(false);
const selectedDecision = ref<DecisionStatusEnum | null>(selectedDecisionType);

const statusToLabels: Record<string, string> = {
  [DecisionStatusEnum.APPROVED]: "Approved",
  [DecisionStatusEnum.INFORMATION_REQUESTED]: "More Information Requested",
  [DecisionStatusEnum.PENDING]: "Pending Decisions",
  [DecisionStatusEnum.DENIED]: "Declined",
};

function toggleIsMenuShowing() {
  isMenuShowing.value = !isMenuShowing.value;
}

function selectDecisionStatus(newDecision: string) {
  const newDecisionStatus = newDecision as DecisionStatusEnum;

  toggleIsMenuShowing();
  selectedDecision.value = newDecisionStatus;
  emit("selectDecisionType", newDecisionStatus);
}
</script>

<template>
  <div class="flex justify-center">
    <div>
      <div class="dropdown relative">
        <button
          class="dropdown-toggle px-6 py-2.5 text-black font-medium text-xs leading-tight rounded shadow-md hover:shadow-lg active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          @click="toggleIsMenuShowing"
        >
          <p v-if="selectedDecision">{{ statusToLabels[selectedDecision] }}</p>
          <p v-else>Select decision type</p>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="caret-down"
            class="w-2 ml-2"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
            ></path>
          </svg>
        </button>
        <ul
          v-if="isMenuShowing"
          class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none"
          aria-labelledby="dropdownMenuButton1"
        >
          <li
            v-for="status in Object.keys(statusToLabels)"
            @click="selectDecisionStatus(status)"
            :key="status"
          >
            <a
              class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
              >{{ statusToLabels[status] }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style></style>
