<script setup lang="ts">
import { ApprovalApplication, DecisionType } from "../models";
import { daysFromToday } from "../utils/datetime";
import {
  formatYearlyVolume,
  formatUseCase,
} from "../utils/customerDataFormater";
import {
  TwoTable,
  TwoTableColumn,
  TwoTableHeader,
} from "@wegift/two-components";
import { ref } from "vue";

defineProps<{
  applications: Array<ApprovalApplication>;
  showApplicationSummary: boolean;
}>();

const getOldestDecisionTime = (decisions: DecisionType[]): Date => {
  if (!decisions.length) new Date();

  let oldest = decisions[0];
  decisions.forEach((decision) => {
    if (decision.decisionTime < oldest.decisionTime) {
      oldest = decision;
    }
  });

  return new Date(oldest.decisionTime);
};

const emit = defineEmits(["applicationTableRowClicked"]);

const applicationTableRowClicked = (clickedRowData: ApprovalApplication) => {
  emit("applicationTableRowClicked", clickedRowData);
};
</script>

<template>
  <div>
    <div v-if="!applications.length" className="text-center">
      You're all set! There are no more customer applications to review at the
      moment. Come back again later.
    </div>
    <div v-else className="rounded-xl bg-white p-4">
      <TwoTable
        :tableData="applications"
        @row-click="applicationTableRowClicked"
      >
        <template #header>
          <TwoTableHeader class="w-56 header-text-custom">
            Customer trading name
          </TwoTableHeader>
          <TwoTableHeader class="w-56 header-text-custom">
            Use case
          </TwoTableHeader>
          <TwoTableHeader class="w-56 header-text-custom">
            Total forecasted spend
          </TwoTableHeader>
          <TwoTableHeader
            class="w-56 header-text-custom"
            v-if="showApplicationSummary"
          >
            Time waiting
          </TwoTableHeader>
          <TwoTableHeader
            class="w-56 header-text-custom"
            v-if="showApplicationSummary"
          >
            Outstanding requests
          </TwoTableHeader>
        </template>
        <template #row="{ rowData }">
          <TwoTableColumn>
            {{ rowData.customerData.tradingName }}
          </TwoTableColumn>
          <TwoTableColumn>
            {{ formatUseCase(rowData.customerData.useCase) }}
          </TwoTableColumn>
          <TwoTableColumn>
            {{ formatYearlyVolume(rowData.customerData.volume) }}
          </TwoTableColumn>
          <TwoTableColumn v-if="showApplicationSummary">
            {{
              daysFromToday(new Date(getOldestDecisionTime(rowData.decisions)))
            }}
          </TwoTableColumn>
          <TwoTableColumn v-if="showApplicationSummary">
            {{ rowData.decisions.length }}
          </TwoTableColumn>
        </template>
      </TwoTable>
    </div>
  </div>
</template>

<style scoped></style>
